@import "./variables.scss";
@import "./bootstrap-overrides.scss";
@import "./flatfile-overrides.scss";
@import url("https://use.typekit.net/qhb2jdq.css");

// spacing
@function pad($num, $padUnit: $padUnit) {
  $padMult: calc($padUnit / 3);
  $pad: $num * $padMult;
  @return $pad;
}

:root {
  // Colors
  --darkNavy: #{$darkNavy};
  --charcoal: #{$charcoal};
  --sherpaTeal: #{$sherpaTeal};
  --sherpaTealHighlight: #{$sherpaTealHighlight};
  --sherpaTealDarker: #{$sherpaTealDarker};
  --sherpaBlue: #{$sherpaBlue};
  --ghostBlue: #{$ghostBlue};
  --yellow: #{$yellow};
  --yellowHighlight: #{$yellowHighlight};
  --orange: #{$orange};
  --orangeHighlight: #{$orangeHighlight};
  --warning: #{$warning};
  --green: #{$green};
  --greenHighlight: #{$greenHighlight};
  --purple: #{$purple};
  --purpleHighlight: #{$purpleHighlight};
  --red: #{$red};
  --redHighlight: #{$redHighlight};
  --archiveGray: #{$archiveGray};
  --disabledGray: #{$disabledGray};
  --darkGray: #{$darkGray};
  --gray: #{$gray};
  --mediumGray: #{$mediumGray};
  --lightGray: #{$lightGray};
  --ultraGray: #{$ultraGray};
  --white: #{$white};
  --lightBlue: #{$lightBlue};
  --highlight: #{$highlight};
  --blueLighter: #{$blueLighter};
  --blueHighlight: #{$blueHighlight};
  --blueDarker: #{$blueDarker};
  --coolGray: #{$coolGray};
  --blue-icon: #{$blueIcon};
  --gray-shadow: #{$grayShadow};
  --gray-border: #{$grayBorder};
  --tint: #{$tint};

  /* Utils */
  --boxShadow: #{$boxShadow};
  --borderRadius: #{$borderRadius};

  /* Gradients */
  --tealBlueGradient: linear-gradient(135deg, var(--sherpaTeal), var(--sherpaBlue));
  --tealBlueGradientFlip: linear-gradient(-45deg, var(--sherpaTeal), var(--sherpaBlue));
  --ghostBlueGradient: linear-gradient(
    315deg,
    var(--blue-icon) 10%,
    var(--ghostBlue) 60%
  );

  --sherpaBlueGradient: linear-gradient(135deg, var(--blueLighter), var(--sherpaBlue));
  --sherpaBlueGradientFlip: linear-gradient(
    -45deg,
    var(--blueLighter),
    var(--sherpaBlue)
  );

  --sherpaTealGradient: linear-gradient(135deg, #45dfd7, var(--sherpaTeal));
  --sherpaTealGradientFlip: linear-gradient(-45deg, #45dfd7, var(--sherpaTeal));

  --warningGradient: linear-gradient(135deg, var(--yellow), var(--orange));
  --warningGradientFlip: linear-gradient(-45deg, var(--yellow), var(--orange));
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.break-word {
  word-break: break-word;
}

.width-fit {
  width: fit-content;
}

* {
  /* defaults to thin scrollbars on firefox */
  scrollbar-width: thin;
  line-height: normal;

  /* thin scrollbars on webkit browsers */
  &::-webkit-scrollbar {
    background-color: transparent;
    width: var(--scrollbarWidth);
  }
  &::-webkit-scrollbar-thumb {
    background: var(--mediumGray);
    border-radius: 10px;
  }
}

// Heading and text
.fw-bold {
  font-weight: 400 !important;
}
.fw-regular {
  font-weight: 400 !important;
}
.fw-black {
  font-weight: 400 !important;
}

body,
html {
  font-family: aktiv-grotesk, sans-serif;
  // font-family: "Lato", Arial, Helvetica, sans-serif;
  overflow-x: hidden;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
.textXL,
.textL,
.textM,
.textS,
.textXS {
  font-family: aktiv-grotesk, sans-serif;
  // font-family: "Lato", Arial, Helvetica, sans-serif;
  line-height: normal;
  hyphens: manual;
}

.react-select__clear-indicator svg {
  height: 15px;
  width: 15px;
}

.textXL {
  font-size: 16px;
  line-height: 1.5;
  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 1.5;
  }
}
.textL {
  font-size: 14px;
  line-height: 1.5;
  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 1.5;
  }
}
.textM {
  font-size: 12px;
  line-height: 1.5;
}
.textS {
  font-size: 10px;
  line-height: 1.25;
}
.textXS {
  font-size: 8px;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.gray {
  color: $gray;
}
.o-100 {
  opacity: 1 !important;
}
.bg-white {
  background: white !important;
}
.flexg-1 {
  flex-grow: 1;
}
.flexg-2 {
  flex-grow: 2;
}
.flexb-auto {
  flex-basis: auto;
}
.flexb-50 {
  flex-basis: 50%;
}
.flexb-100 {
  flex-basis: 100%;
}

.lightBlue {
  color: var(--blueHighlight);
}
.darkGray {
  color: $darkGray;
}
.blue {
  color: $sherpaBlue;
}
.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    display: none;
  } /* Webkit browsers */
}
.white {
  color: white;
}
ul {
  list-style: none;
  &.bulleted {
    list-style: disc;
  }
}

select,
option,
*[onclick],
*[class*="button"],
*[class*="btn"],
*[class*="Btn"],
*[data-test*="button"],
.statusAction,
.unread,
.nav-link {
  cursor: pointer;
}

.form-control-plaintext {
  cursor: not-allowed !important;
  height: calc(1em + 1rem);
}

.form-control {
  font-size: 1rem !important;
}

label.required {
  &:after {
    content: "*";
    color: var(--sherpaTeal);
  }
}
.disabled,
[disabled="true"] {
  cursor: not-allowed;
  pointer-events: none;
}
:root {
  --padUnit: #{$padUnit};
  --pad1: #{pad(1)};
  --pad2: #{pad(2)};
  --pad3: #{pad(3)};
  --pad4: #{pad(4)};
  --pad5: #{pad(5)};
  --pad6: #{pad(6)};
  --pad7: #{pad(7)};
  --pad8: #{pad(8)};
  --pad9: #{pad(9)};
  --scrollbarWidth: #{$scrollbarWidth};
}

.icon,
svg {
  transition: transform 0.15s;
}

#root > .displayedData {
  display: flex;
  width: 100%;
}

textarea {
  resize: none;
}

.no-br {
  border-radius: 0 !important;
}

.bb-1 {
  border-bottom: 1px solid var(--mediumGray);
}
.bb-2 {
  border-bottom: 2px solid var(--mediumGray);
}

.modal-header {
  align-items: center;
}

// custom checkbox
.custom-checkbox {
  --leftPad: 1.8rem;
  --boxSize: 1.25rem;

  padding: var(--pad1) 0 var(--pad1) var(--leftPad);

  .custom-control-input {
    width: var(--leftPad);
    height: var(--leftPad);
    cursor: pointer !important;
  }
  .custom-control-label {
    cursor: pointer !important;
    &:after,
    &:before {
      top: 0;
      left: calc(0rem - var(--leftPad));
      width: var(--boxSize);
      height: var(--boxSize);
    }
  }
  .custom-control-input:checked {
    & ~ .custom-control-label::before {
      background: var(--sherpaBlue);
    }
    & ~ .custom-control-label {
      font-weight: 900;
    }
  }
}

.empty-input-error {
  position: relative;
  animation: shake 0.1s linear;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% {
    left: -3px;
  }
  100% {
    right: -3px;
  }
}

::-webkit-scrollbar {
  border-radius: 10;
}

.add-shadow {
  box-shadow: 2px 2px 5px $gray;
}

.pointer {
  cursor: pointer;
}

.truncated-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.svg-inline--fa {
  vertical-align: -0.15em;
}

.touch-action-none {
  touch-action: none;
}

.pre-line {
  white-space: pre-line;
}

.no-outline {
  outline: none;
  &:focus {
    outline: none;
  }
}

.justify-content-between {
  justify-content: space-between;
}
