// colors
$darkNavy: #171e29;
$charcoal: #232d3d;
$sherpaTeal: #14c4ce;
$sherpaTealHighlight: lighten($sherpaTeal, 48%);
$sherpaTealDarker: darken($sherpaTeal, 3%);
$lightBlue: #65c8ce;
$highlight: lighten($lightBlue, 30%);
$sherpaBlue: #235bed;
$blueHighlight: lighten($sherpaBlue, 35%);
$blueLighter: #4888ff;
$blueDarker: darken($sherpaBlue, 7.5%);
$ghostBlue: #f3f6ff;
$coolGray: #ecf1fd;
$yellow: #ffc344;
$yellowHighlight: lighten($yellow, 30%);
$warning: #ffba28;
$orange: #ff8646;
$orangeHighlight: lighten($orange, 30%);
$green: #16ce16;
$greenHighlight: lighten($green, 50%);
$purple: #8b48e0;
$purpleHighlight: lighten($purple, 35%);
$red: #ff0000;
$redHighlight: lighten($red, 40%);
$darkGray: #747474;
$archiveGray: #dadce5;
$gray: #b5b5b5;
$archiveGray: #dadce5;
$disabledGray: #b5b5b5;
$mediumGray: #d8d8d8;
$lightGray: #f3f3f3;
$ultraGray: #fafafa;
$blueIcon: #d3defb;
$grayShadow: #e8eaf2;
$grayBorder: #979797;
$white: #ffffff;
$tint: $lightGray;

$padUnit: 1.5rem;
$scrollbarWidth: 10px;

$breakpoints: (
  min-sm: "576px",
  min-md: "768px",
  min-lg: "992px",
  min-xl: "1281px",
  max-xs: "575.98px",
  max-sm: "767.98px",
  max-md: "991.98px",
  max-lg: "1280.98px",
);

$boxShadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
$borderRadius: 7px;
