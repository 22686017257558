@import "variables.scss";

$btn-border-radius: 28px;
$btn-border-radius-lg: 28px;
$btn-border-radius-sm: 28px;

$input-btn-padding-x: 1rem;
$input-btn-padding-x-sm: 0.75rem;
$input-btn-padding-x-lg: 1.5rem;

$modal-content-border-radius: 1.3rem;
$modal-content-inner-border-radius: calc(1.3rem - 1px);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1281px,
);

$theme-colors: (
  "primary": $sherpaBlue,
  "secondary": $darkNavy,
  "success": $green,
  "info": $orange,
  "warning": $warning,
  "danger": $red,
  "light": $mediumGray,
  "dark": $darkNavy,
);

$grid-gutter-width: $padUnit;
$spacer: $padUnit;
$headings-margin-bottom: 0.5em;
$form-grid-gutter-width: $padUnit;

$input-padding-x: 0;
$input-padding-y: 1rem;
$input-bg: transparent;
$input-color: $darkNavy;
$input-placeholder-color: $gray;
$input-border-width: 0;
$input-font-size: 1rem;
$input-focus-border-color: transparent;
$input-focus-box-shadow: none;

$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-link-active-color: transparent;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent;
$nav-tabs-border-color: transparent;
$nav-tabs-border-width: 0;

$table-cell-padding: $padUnit;

$label-margin-bottom: 0px;

$popover-border-width: 0px;

.popover {
  box-shadow: 0 0 15px -4px #b7b7b7;
}
.nav-tabs .nav-link {
  border: none !important;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: none !important;
}

.navbar-dark .navbar-toggler {
  border: none;
}

.navbar-dark .navbar-nav .nav-link {
  color: white !important;
}

.btn-secondary:hover {
  background-color: #090c10bf !important;
  border-color: #090c10bf !important;
}

.btn-lg,
.btn-group-lg > .btn {
  height: 40px !important;
  font-size: 15px !important;
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.btn {
  font-weight: 600 !important;
  font-size: 14px !important;
  height: 32px !important;
  line-height: 1 !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.btn-lg,
.btn-group-lg > .btn {
  line-height: 1.5 !important;
}

.btn-sm,
.btn-group-sm {
  font-size: 12px !important;
  height: 24px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
}

.form-check {
  padding-left: 1.75rem !important;

  &-input {
    margin-left: -1.75rem !important;
  }
}
.btn-xl {
  box-shadow: 0 0 20px -8px rgba(0, 0, 0, 0.95);
  padding: var(--pad2) var(--pad3) !important;
  border-radius: 3em !important;
  height: 48px !important;
}
.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0 !important;
}
textarea.form-control {
  height: auto !important;
}

.tab-content {
  flex-grow: 1;
  display: flex;

  .tab-pane {
    flex: 1;
  }
}
// When a modal is opened when the page has a scrollbar,
// bootstrap adds a padding-right:5px... WHY!
.modal-open {
  padding-right: 0px !important;
}

@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";

.form-control:disabled,
.form-control[readOnly] {
  background-color: var(--lightGray);
}

.tooltip-inner {
  padding: 1rem;
  background-color: var(--lightGray);
  color: var(--black);
  box-shadow: 2px 2px 8px var(--gray);
  border-radius: 15px;
  font-size: 0.95rem;
}

.tooltip {
  font-family: aktiv-grotesk, sans-serif;
  &.show {
    opacity: 1;
  }
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: var(--lightGray);
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: var(--lightGray);
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: var(--lightGray);
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: var(--lightGray);
}

.alert-dismissible {
  .close {
    bottom: 0;
    padding: 0 1.25rem;
  }
}

.custom-control {
  print-color-adjust: exact;
}

.custom-control-label::before {
  border: 2px solid var(--mediumGray) !important;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--sherpaBlue) !important;
}
.custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: var(--disabledGray) !important;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: var(--disabledGray);
  border-color: var(--disabledGray) !important;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  top: 0.5px;
}
