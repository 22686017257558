.ListItem {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: flex;
}

.BouncingListItem {
  transition: transform 0.3s ease-out;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  height: 100%;
  display: flex;
}

.Wrapper {
  position: relative;
  transition: max-height 0.3s ease;
  max-height: 1000px;
  transform-origin: top;
  overflow: hidden;
  width: 100%;
  pointer-events: none;
}
.Wrapper .action {
  pointer-events: all;
}
